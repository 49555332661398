import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";
import checklogin from "./checkLogin";
import axios from "axios";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomNavbar from "../directives/navbar";
import Sidebar from "../directives/barside";
import host from "./utils";
import profile2 from "../Assets/Images/topboxlogo.png";
import infomationLoginUser from "./LoginUSerInfo";
import ServiseAssign from "./ServiseAssign";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import followups from "../Assets/Images/followup.jpg";
import { useForm } from 'react-hook-form';

export default function FilingManager() {
  const [data, setData] = useState([]);
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [userData, setUserData] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [updatemodal, setUpdateModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [salesData, setSalesData] = useState([]);
  const updatetogglecloase = () => setUpdateModal(!updatemodal);
  var userId = localStorage.getItem("userId");
  const [show, setShow] = useState(false);
  const [fillingProccessShow, setFillingProccessShow] = useState(false);
  const [selectedLeadIds, setSelectedLeadIds] = useState([]);
  const [selectedIdLead, setSelectedIdLead] = useState("");
  const [showfollowup, setShowFollowup] = useState(false);
  const [checkedItems, setCheckedItems] = useState({
    "Case Initiated": false,
    "File Initiated or docs checklist sent": false,
    "Docs received & Pending docs sent": false,
    "Sop Or letters prepration & Forms prep": false,
    "SOP or Letters sent to client": false,
  });
  const [selectedService, setSelectedService] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [apiResponse, setApiResponse] = useState(null);
  const [followup, setFollowup] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [caseInitiatedChecked, setCaseInitiatedChecked] = useState(false);
  const [fileInitiatedChecked, setFileIntiatedChecked] = useState(false);
  const [docReceivedChecked, setDocsReceivedChecked] = useState(false);
  const [soppreprationChecked, setSoppreprationChecked] = useState(false);
  const [sopLetterChecked, setSopLettersChecked] = useState(false);
  const [confirmReceivedChecked, setConfirmRecievedChecked] = useState(false);
  const [fileSentChecked, setFilesentChecked] = useState(false);
  const [filessubmitted, setFilessubmitted] = useState(false);
  const [visaApprovedChecked, setVisaapprovedChecked] = useState(false);
  const [visaRefusalChecked, setVisaRefusalChecked] = useState(false);
  const { register, handleSubmit, formState: { errors }, getValues, setValue, reset } = useForm();
  const { register: register1, handleSubmit: handleSubmit1, setValue: setValue1, formState: { errors: errors1 }, reset: reset1 } = useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [serviceName, setServiceName] = useState([]);

  var filingtamid = localStorage.getItem('userId');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseFillingProccess = () => {
    reset1();
    setFillingProccessShow(false);
  }

  const handleShowFillingProccess = (selectedId) => {
    setFillingProccessShow(true)
    findServiceByLeadId(selectedId);
    setSelectedIdLead(selectedId)
  };

  const findServiceByLeadId = async (id) => {
    try {
      const response = await axios.post(`${host}/GetByIdLead`, {
        id: id,
      });
      if (response.status == 200) {
        if (response.data.data.Filing_Process.length > 0) {
          for (let i = response.data.data.Filing_Process.length - 1; i >= 0; i--) {
            const val = response.data.data.Filing_Process[i];
            if (val.caseInitiated == 'Case Initiated') {
              setValue1('caseInitiated', true);
              const inputElement = document.querySelector(`[name="caseInitiated"]`);
              const fileIntiated = document.querySelector(`[name="fileIntiated"]`);
              if (inputElement) {
                inputElement.disabled = true;
                fileIntiated.disabled = false; // Disable the field
              }
            }
            if (val.caseInitiated === 'true' && val.fileIntiated === 'File Initiated or docs checklist sent') {
              setValue1('fileIntiated', true);
              const inputElement = document.querySelector(`[name="fileIntiated"]`);
              const docsReceived = document.querySelector(`[name="docsReceived"]`);
              if (inputElement) {
                inputElement.disabled = true;
                docsReceived.disabled = false; // Disable the field
              }
            }
            if (val.caseInitiated == 'true' && val.fileIntiated == 'true' && val.docsReceived == 'Docs received & Pending docs sent') {
              setValue1('docsReceived', true);
              const inputElement = document.querySelector(`[name="docsReceived"]`);
              const sopprepration = document.querySelector(`[name="sopprepration"]`);
              if (inputElement) {
                inputElement.disabled = true;
                sopprepration.disabled = false; // Disable the field
              }
            }
            if (val.caseInitiated == "true" && val.fileIntiated == "true" && val.docsReceived == "true" && val.sopprepration == 'Sop Or letters prepration & Forms prep') {
              setValue1('sopprepration', true);
              const inputElement = document.querySelector(`[name="sopprepration"]`);
              const sopletters = document.querySelector(`[name="sopletters"]`);
              if (inputElement) {
                inputElement.disabled = true;
                sopletters.disabled = false; // Disable the field
              }
            }
            if (val.caseInitiated == 'true' && val.fileIntiated == 'true' && val.docsReceived == 'true' && val.sopprepration == 'true' && val.sopletters == 'SOP or Letters sent to client') {
              setValue1('sopletters', true);
              const inputElement = document.querySelector(`[name="sopletters"]`);
              const confirmrecieved = document.querySelector(`[name="confirmrecieved"]`);
              if (inputElement) {
                inputElement.disabled = true;
                confirmrecieved.disabled = false; // Disable the field
              }
            }
            if (val.caseInitiated == 'true' && val.fileIntiated == 'true' && val.docsReceived == 'true' && val.sopprepration == 'true' && val.sopletters == 'true' && val.confirmrecieved == 'Confirmation received on SOP, letters and docs, Forms confirmation') {
              setValue1('confirmrecieved', true);
              const inputElement = document.querySelector(`[name="confirmrecieved"]`);
              const filesent = document.querySelector(`[name="filesent"]`);
              if (inputElement) {
                inputElement.disabled = true;
                filesent.disabled = false; // Disable the field
              }
            }
            if (val.caseInitiated == 'true' && val.fileIntiated == 'true' && val.docsReceived == 'true' && val.sopprepration == 'true' && val.sopletters == 'true' && val.confirmrecieved == 'true' && val.filesent == 'File sent for Upload or submission') {
              setValue1('filesent', true);
              const inputElement = document.querySelector(`[name="filesent"]`);
              const visaapproved = document.querySelector(`[name="visaapproved"]`);
              if (inputElement) {
                inputElement.disabled = true;
                visaapproved.disabled = false; // Disable the field
              }
            }
            if (val.caseInitiated == 'true' && val.fileIntiated == 'true' && val.docsReceived == 'true' && val.sopprepration == 'true' && val.sopletters == 'true' && val.confirmrecieved == 'true' && val.filesent == 'true' && val.visaapproved == 'Visa approved') {
              setValue1('visaapproved', true);
              const inputElement = document.querySelector(`[name="visaapproved"]`);
              const visarefusal = document.querySelector(`[name="visarefusal"]`);
              if (inputElement) {
                inputElement.disabled = true;
                visarefusal.disabled = false;
              }
            }
            if (val.caseInitiated == 'true' && val.fileIntiated == 'true' && val.docsReceived == 'true' && val.sopprepration == 'true' && val.sopletters == 'true' && val.confirmrecieved == 'true' && val.filesent == 'true' && val.visaapproved == 'true' && val.visarefusal == 'Visa refusal') {
              setValue1('visarefusal', true);
              const inputElement = document.querySelector(`[name="visarefusal"]`);
              if (inputElement) {
                inputElement.disabled = true;
              }
            }
          }
        } else {
          console.log("Not find any filling proccess");
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  useEffect(() => {
    checklogin();
    infomationLoginUser();
    fetchData();
    handleAssignLeadClick();
    fetchServiceName();
  }, []);

  const handleAssignLeadClick = () => {
    axios
      .get(`${host}/GetAllFillinfTeam`)
      .then((response) => {
        setSalesData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchServiceName = async () => {
    try {
      const response = await axios.get(`${host}/listservice-name`);
      if (response.data.error == false) {
        setServiceName(response.data);
      }
    } catch (error) {
      console.error("Error during API request:", error);
    }
  };

  const fetchData = async () => {
    try {
      const date = new Date();
      const item = {
        role: "manager",
        current_date: `${String(date.getMonth() + 1).toString().padStart(1, '0')}/${String(date.getDate().toString().padStart(1, '0'))}/${date.getFullYear()}`,
        id: localStorage.getItem("userId")
      }
      const response = await axios.post(`${host}/getcase-todaylead`, item);
      if (response.data.error == false) {
        setData(response.data.data);
      }
    } catch (error) {
      console.error("Error during API request:", error);
    }
  };

  const handleButtonClick = () => {
    if (selectedLeadIds.length === 0) {
      MySwal.fire({
        icon: "warning",
        title: "Warning!",
        text: "Please select both a lead and a role before submitting.",
      });
      return;
    }
    const data = {
      id: selectedLeadIds,
      // status: "4",
      //     Lead_AssignID: selectedId,
      Services: selectedValue,
    };

    axios
      .post(`${host}/UpdateLeadAssign`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.error == true) {
          MySwal.fire({
            icon: "warning",
            title: "warning!",
            text: `${response.data.message}`,
          });
        } else {
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: `${response.data.message}`,
          });
          fetchData();
        }
      })
      .catch((error) => {
        MySwal.fire({
          icon: "error",
          title: "Error!",
          text: `${error}`,
        });
      });
  };

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  let paginatedData = [];
  if (Array.isArray(data)) {
    const startIndex = (currentPage - 1) * perPage;
    const endIndex = startIndex + perPage;
    paginatedData = data.slice(startIndex, endIndex);
  } else {
    console.error("Data is not an array.");
  }

  const getLastCompletedStatus = (filingProcess) => {
    if (!filingProcess.length) {
      return <div className="btn btn-success btn-sm">Case Initiated</div>;
    }
  
    for (let i = filingProcess.length - 1; i >= 0; i--) {
      const val = filingProcess[i];
  
      if (val.caseInitiated === "true" && val.fileIntiated === "true" && val.docsReceived === "true" && 
          val.sopprepration === "true" && val.sopletters === "true" && val.confirmrecieved === "true" && 
          val.filesent === "true" && val.filessubmitted == 'true' && val.visaapproved === "true" && val.visarefusal === "Visa refusal") {
        return <div className="btn btn-success btn-sm">Visa Refusal</div>;
      }
  
      if (val.caseInitiated === "true" && val.fileIntiated === "true" && val.docsReceived === "true" && 
          val.sopprepration === "true" && val.sopletters === "true" && val.confirmrecieved === "true" && 
          val.filesent === "true" && val.filessubmitted == 'true' && val.visaapproved === "Visa approved") {
        return <div className="btn btn-success btn-sm">Visa Approved</div>;
      }
  
      if (val.caseInitiated === "true" && val.fileIntiated === "true" && val.docsReceived === "true" && 
          val.sopprepration === "true" && val.sopletters === "true" && val.confirmrecieved === "true" && val.filesent === "true" && val.filessubmitted == 'Files Submitted') {
        return <div className="btn btn-success btn-sm">Files Submitted</div>;
      }

      if (val.caseInitiated === "true" && val.fileIntiated === "true" && val.docsReceived === "true" && 
          val.sopprepration === "true" && val.sopletters === "true" && val.confirmrecieved === "true" && val.filesent === "File sent for Upload or submission") {
        return <div className="btn btn-success btn-sm">File sent for Upload or submission</div>;
      }
  
      if (val.caseInitiated === "true" && val.fileIntiated === "true" && val.docsReceived === "true" && 
          val.sopprepration === "true" && val.sopletters === "true" && val.confirmrecieved === "Confirmation received on SOP, letters and docs, Forms confirmation") {
        return <div className="btn btn-success btn-sm">Confirmation received on SOP, letters and docs, Forms confirmation</div>;
      }
  
      if (val.caseInitiated === "true" && val.fileIntiated === "true" && val.docsReceived === "true" && val.sopprepration === "true" && val.sopletters === "SOP or Letters sent to client") {
        return <div className="btn btn-success btn-sm">SOP or Letters sent to client</div>;
      }
  
      if (val.caseInitiated === "true" && val.fileIntiated === "true" && val.docsReceived === "true" && val.sopprepration === "Sop Or letters prepration & Forms prep") {
        return <div className="btn btn-success btn-sm">Sop Or letters preparation & Forms prep</div>;
      }
  
      if (val.caseInitiated === "true" && val.fileIntiated === "true" && val.docsReceived === "Docs received & Pending docs sent") {
        return <div className="btn btn-success btn-sm">Docs received & Pending docs sent</div>;
      }
  
      if (val.caseInitiated === "true" && val.fileIntiated === "File Initiated or docs checklist sent") {
        return <div className="btn btn-success btn-sm">File Initiated or docs checklist sent</div>;
      }
  
      if (val.caseInitiated === "Case Initiated") {
        return <div className="btn btn-success btn-sm">Case Initiated</div>;
      }
    }
  
    return <div className="btn btn-success btn-sm">Case Initiated</div>;
  };

  const columns = [
    {
      name: "Sr. No.",
      selector: (row, index) => index + 1 + (currentPage - 1) * perPage,
      sortable: true,
      width: '100px !important',
    },
    {
      name: "Full Name",
      selector: (row) => (
        <div onClick={() => userFollowupShow(row._id)} style={{ cursor: "pointer" }}>
          <span className="text-info">{`${row.first_name} ${row.last_name}`}</span>
        </div>
      ),
      sortable: true,
      width: "200px !important"
    },
    {
  name: "Status",
  selector: (row) => getLastCompletedStatus(row.Filing_Process),
  sortable: true,
  width: "300px !important"
},
    {
      name: "Services",
      selector: (row) => <div>{row.Services}</div>,
      sortable: true,
    },
    {
      name: 'Followup',
      selector: (row) => (
        <>
          <img
            src={followups}
            alt="Profile"
            onClick={() => handleShowFolloup(row._id)}
            style={{ cursor: "pointer" }}
          />
        </>
      ),
      sortable: true,
    },
    {
      name: "Filing Process",
      selector: (row) => row.id,
      sortable: true,
      cell: (row) => (
        <div className="text-info" style={{ cursor: "pointer" }} onClick={() => handleShowFillingProccess(row._id)} >
          Process
        </div>
      ),
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "250px !important"
    },
    {
      name: "Contact no.",
      selector: (row) => row.contact_number,
      sortable: true,
    },
    {
      name: "Country.",
      selector: (row) => row.country_name,

      sortable: true,
    },
    {
      name: "Sales Executive.",
      selector: (row) => row.Lead_AssignName,
      sortable: true,
    },
    {
      name: "Lead Type.",
      selector: (row) => row.Lead_Type,
      sortable: true,
    },
    {
      name: "Gender",
      selector: (row) => row.gender,
      sortable: true,
      width: '100px !important',
    },
    {
      name: "Alternate Contact No.",
      selector: (row) => row.alt_contact_number,
      sortable: true,
    },
    {
      name: "Age.",
      selector: (row) => row.age == "undefined" ? "N/A" : row.age,
      sortable: true,
      width: "100px !important"
    },
    {
      name: "State.",
      selector: (row) => row.state_name,
      sortable: true,
    },
    {
      name: "Education.",
      selector: (row) => row.education,
      sortable: true,
    },
    {
      name: "Lead Source.",
      selector: (row) => row.Lead_Source,
      sortable: true,
    },
  ];

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setCurrentPage(1); // Reset to first page when changing rows per page
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleShowFolloup = async (id) => {
    try {
      const response = await axios.post(`${host}/GetByIdLead`, {
        id: id,
      });
      setApiResponse(response.data);
      const currentDate = new Date().toISOString().split('T')[0];
      setValue('Followup_Date_fiiling_team', currentDate);
    } catch (error) {
      console.error(error);
    }
    setSelectedIdLead(id);
    setShowFollowup(true);
  }

  const userFollowupShow = async (id) => {
    try {
      const response = await axios.post(`${host}/GetByIdLead`, {
        id: id,
      });
      setApiResponse(response.data);
      if (response.data.data.Filing_Process.length > 0) {
        for (let i = response.data.data.Filing_Process.length - 1; i >= 0; i--) {
          const val = response.data.data.Filing_Process[i];
          if (val.caseInitiated == 'Case Initiated') {
            setCaseInitiatedChecked(true);
          }
          if (val.caseInitiated == "true" && val.fileIntiated == 'File Initiated or docs checklist sent') {
            setFileIntiatedChecked(true);
          }
          if (val.caseInitiated == 'true' && val.fileIntiated == 'true' && val.docsReceived == 'Docs received & Pending docs sent') {
            setDocsReceivedChecked(true);
          }
          if (val.caseInitiated == "true" && val.fileIntiated == "true" && val.docsReceived == "true" && val.sopprepration == 'Sop Or letters prepration & Forms prep') {
            setSoppreprationChecked(true);
          }
          if (val.caseInitiated == 'true' && val.fileIntiated == 'true' && val.docsReceived == 'true' && val.sopprepration == 'true' && val.sopletters == 'SOP or Letters sent to client') {
            setSopLettersChecked(true);
          }
          if (val.caseInitiated == 'true' && val.fileIntiated == 'true' && val.docsReceived == 'true' && val.sopprepration == 'true' && val.sopletters == 'true' && val.confirmrecieved == 'Confirmation received on SOP, letters and docs, Forms confirmation') {
            setConfirmRecievedChecked(true);
          }
          if (val.caseInitiated == 'true' && val.fileIntiated == 'true' && val.docsReceived == 'true' && val.sopprepration == 'true' && val.sopletters == 'true' && val.confirmrecieved == 'true' && val.filesent == 'File sent for Upload or submission') {
            setFilesentChecked(true);
          }

          if (val.caseInitiated == 'true' && val.fileIntiated == 'true' && val.docsReceived == 'true' && val.sopprepration == 'true' && val.sopletters == 'true' && val.confirmrecieved == 'true' && val.filesent == 'true' && val.filessubmitted == 'Files Submitted') {
            setFilessubmitted(true);
          }
          if (val.caseInitiated == 'true' && val.fileIntiated == 'true' && val.docsReceived == 'true' && val.sopprepration == 'true' && val.sopletters == 'true' && val.confirmrecieved == 'true' && val.filesent == 'true' && val.filessubmitted == 'true' &&  val.visaapproved == 'Visa approved') {
            setVisaapprovedChecked(true);
          }
          if (val.caseInitiated == 'true' && val.fileIntiated == 'true' && val.docsReceived == 'true' && val.sopprepration == 'true' && val.sopletters == 'true' && val.confirmrecieved == 'true' && val.filesent == 'true' && val.filessubmitted == 'true' && val.visaapproved == 'true' && val.visarefusal == 'Visa refusal') {
            setVisaRefusalChecked(true);
          }

        }
      } else {
        console.log("Not find any filling proccess");
      }
    } catch (error) {
      console.error(error);
    }
    setFollowup(true)
  }

  const followupClose = () => {
    setFollowup(false);
    setCaseInitiatedChecked(false);
    setFileIntiatedChecked(false);
    setDocsReceivedChecked(false);
    setSoppreprationChecked(false);
    setSopLettersChecked(false);
    setConfirmRecievedChecked(false);
    setFilesentChecked(false);
    setFilessubmitted(false);
    setVisaapprovedChecked(false);
    setVisaRefusalChecked(false);
  }

  const handleCloseFolloup = () => {
    reset();
    setShowFollowup(false);
  }

  if (apiResponse && apiResponse.data) {
    var followUpStatus = apiResponse.data
    const followUpDates = apiResponse.data.Followup_Date_fiiling_team;
    var followupdatepayment = apiResponse.data.Followup_Date

    const NextFollowupDate = apiResponse.data.Next_Followup_Date_fiiling_team;
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };

    // Format regular follow-up dates
    var formattedFollowUpDates = followUpDates.map(dateString => {
      if (dateString) {
        const followUpDate = new Date(dateString);
        return followUpDate.toLocaleDateString('en-US', options);
      } else {
        return null;
      }
    });
    var followupdatepaymentData = followupdatepayment.map(dateStringpayment => {
      if (dateStringpayment) {
        const followUpDatepayment = new Date(dateStringpayment);
        return followUpDatepayment.toLocaleDateString('en-US', options);
      } else {
        return null;
      }
    });
    // Format Next Follow-up Date
    var formattedNextFollowupDate = NextFollowupDate.map(dateStringnext => {
      if (dateStringnext) {
        const followUpDateNext = new Date(dateStringnext);
        return followUpDateNext.toLocaleDateString('en-US', options);
      } else {
        return null;
      }
    });
    var notes = apiResponse.data.Notes_fiiling_team
    var TotalAmount = apiResponse.data.Total_Amount
  }

  const onSubmit = async (data) => {
    const item = {
      id: selectedIdLead,
      data: data,
      Filing_Team_Status: apiResponse.data.Filing_Process,
      fillingUser: "Manager"
    }
    const response = await axios.post(`${host}/updatefilingteam`, item, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data) {
      MySwal.fire({
        icon: "success",
        title: "Success!",
        text: "Lead successfully update.",
      });
      window.location.reload();
    }
  };

  const serviceUpdate = async (data) => {
    const items = {
      id: selectedIdLead,
      Filing_TeamID_process: filingtamid,
      Filing_Process: data,
    };
    axios
      .post(`${host}/UpdateLeadAssignprocess`, items, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (
          response.data.message == "Lead is already assigned. Cannot reassign."
        ) {
          MySwal.fire({
            icon: "warning",
            title: "warning!",
            text: "Lead is already assigned. Cannot reassign.",
          });
        } else {
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: " success.",
          });
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
        MySwal.fire({
          icon: "error",
          title: "Error!",
          text: "An error occurred while saving your data.",
        });
      });
  };

  const formatLeadDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());

    return `${day}-${month}-${year}`;
  };

  const formatLeadTime = (timeString) => {
    const time = new Date(timeString);
    const hours = String(time.getHours()).padStart(2, '0');
    const minutes = String(time.getMinutes()).padStart(2, '0');
    const seconds = String(time.getSeconds()).padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    const newCheckedItems = { ...checkedItems };
    setCheckedItems(newCheckedItems);
  }, []);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData =
    data && data
      ? data.filter((row) => {
        return (
          Object.values(row).some(
            (value) =>
              typeof value === "string" &&
              value.toLowerCase().includes(searchText.toLowerCase())
          )
        );
      })
      : [];

  const handleButtonAssign = () => {
    if (selectedLeadIds.length === 0 || !selectedId) {
      MySwal.fire({
        icon: "warning",
        title: "Warning!",
        text: "Please select both a lead and a role before submitting.",
      });
      return; // Stop further execution if conditions are not met
    }
    const data = {
      id: selectedLeadIds,
      status: "4",
      Filing_TeamID: selectedId,
      Filing_TeamName: selectedName,
    };

    axios
      .post(`${host}/UpdateLeadAssign`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.error == true) {
          MySwal.fire({
            icon: "warning",
            title: "warning!",
            text: `${response.data.message}`,
          });
        } else {
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: `${response.data.message}`,
          });
          fetchData();
          setSelectedLeadIds([]);
          // window.location.href = 'http://192.168.29.47:3001/FilingManager';
          window.location = "https://checkcheckservices.in/FilingManager";
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
        MySwal.fire({
          icon: "error",
          title: "Error!",
          text: "An error occurred while saving your data.",
        });
      });
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    const [id, name] = selectedValue.split(":");
    setSelectedId(id);
    setSelectedName(name);
  };

      function extractDateTime(str) {
        const regex = /@\s*(\d{2}-\d{2}-\d{4}),?\s*(\d{2}:\d{2}:\d{2})/;
        const match = str.match(regex);
        return match ? `${match[1]} ${match[2]}` : null; // Combine the date and time without the comma
    }
    
    function removeDateTime(str) {
      const index = str.indexOf('@'); // Find the position of '@'
      return index !== -1 ? str.slice(0, index).trim() : str; // Remove everything after '@'
    }
     // Combine all entries into a single array with type metadata
    const combinedData = [
        ...(followUpStatus?.Followup_Date_fiiling_team || []).map((date, index) => ({
        type: "filing_team",
        date,
        status: followUpStatus?.Filing_Team_Status?.[index] || "N/A",
        notes: followUpStatus?.Notes_fiiling_team?.[index] || "N/A",
        nextFollowUpDate: followUpStatus?.Next_Followup_Date_fiiling_team?.[index] || null,
        })),
        ...(followUpStatus?.Followup_Date || []).map((date, index) => ({
        type: "sales",
        date,
        status: followUpStatus?.status_convert?.[index] || "N/A",
        notes: followUpStatus?.Notes_sales?.[index] || "N/A",
        nextFollowUpDate: followUpStatus?.Next_Followup_Date?.[index] || null,
        })),
        ...(followUpStatus?.other_note || []).map((note) => ({
        type: "other_note",
        date: extractDateTime(note),
        notes: removeDateTime(note),
        status: "Notes",
        nextFollowUpDate: null,
        })),
    ];

    // Sort by date (latest first)
    const sortedData = combinedData.sort((a, b) => new Date(b.date) - new Date(a.date));
  return (
    <>
      <Sidebar />
      <CustomNavbar />
      <div className="main-content">
        <div className="container-fluid">
          <div className="topbox">
            <div className="row align-items-center">
              <div className="col-md-8">
                <div className="left-logo">
                  <div>
                    <img src={profile2} alt="Logo" className="logo" />
                  </div>
                  <p className="topboxttex">Lead Management</p>
                </div>
              </div>
              <div className="col-md-4 ">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <a href="dashboard">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Leads
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>

          <div className="row pt-3 my-3">
            <div className="col-md-3">
              <div className="wrap mt-4">
                <div className="search">
                  <input
                    type="text"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder="Search"
                    style={{ borderRadius: "7px", height: "35px" }}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="row tab-content">
                <div
                  id="list-view"
                  className="tab-pane fade active show col-lg-12"
                >
                  <div className="card">
                    <div className="card-header">
                      <DataTable
                        className="custom-data-table"
                        columns={columns}
                        data={filteredData}
                        pagination
                        paginationTotalRows={data.length}
                        onChangePage={handlePageChange}
                        paginationPerPage={perPage}
                        paginationRowsPerPageOptions={[10, 20, 30]} // Customize rows per page options
                        onChangeRowsPerPage={handlePerPageChange}
                        paginationPerPageLabel="Rows per page"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Offcanvas show={fillingProccessShow} onHide={handleCloseFillingProccess} placement={"end"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filing Process</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <form onSubmit={handleSubmit1(serviceUpdate)}>
            {/* <label htmlFor="notes">Notes</label>
                        <div className="form-floating">
                            <input className="form-control" placeholder="Leave a comment here" id="notes" name="notes"
                                {...register('notes', { required: 'Please enter the notes' })} />
                            {errors.notes && (
                                <div className="error-message">{errors.notes.message}</div>
                            )}
                        </div> */}

            <span className="col-12">Require Document</span>

            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="flexCheckChecked" name="caseInitiated"
                value="Case Initiated" {...register1('caseInitiated')} />
              <label className="form-check-label" htmlFor="caseInitiated">
                Case Initiated
              </label>
            </div>

            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="flexCheckChecked" name="fileIntiated"
                value="File Initiated or docs checklist sent" {...register1('fileIntiated', { disabled: true })} />
              <label className="form-check-label" htmlFor="fileIntiated">
                File Initiated or docs checklist sent
              </label>
            </div>

            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="flexCheckChecked" name="docsReceived"
                value="Docs received & Pending docs sent" {...register1('docsReceived')} disabled />
              <label className="form-check-label" htmlFor="docsReceived">
                Docs received & Pending docs sent
              </label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="flexCheckChecked" name="sopprepration"
                value="Sop Or letters prepration & Forms prep" {...register1('sopprepration')} disabled />
              <label className="form-check-label" htmlFor="sopprepration">
                SOP Or letters prepration & Forms prep
              </label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="flexCheckChecked" name="sopletters"
                value="SOP or Letters sent to client" {...register1('sopletters')} disabled />
              <label className="form-check-label" htmlFor="sopletters">
                SOP or Letters sent to client
              </label>
            </div>

            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="flexCheckChecked" name="confirmrecieved" value="Confirmation received on SOP, letters and docs, Forms confirmation" {...register1('confirmrecieved')} disabled />
              <label className="form-check-label" htmlFor="confirmrecieved">
                Confirmation received on SOP, letters and docs, Forms confirmation
              </label>
            </div>

            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="flexCheckChecked" name="filesent"
                value="File sent for Upload or submission" {...register1('filesent')} disabled />
              <label className="form-check-label" htmlFor="filesent">
                File sent for Upload or submission
              </label>
            </div>

            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="flexCheckChecked" name="visaapproved"
                value="Visa approved" {...register1('visaapproved')} disabled />
              <label className="form-check-label" htmlFor="visaapproved">
                Visa approved
              </label>
            </div>

            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="flexCheckChecked" name="visarefusal"
                value="Visa refusal" {...register1('visarefusal')} disabled />
              <label className="form-check-label" htmlFor="visarefusal">
                Visa refusal
              </label>
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-primary me-2">
                Submit
              </button>
            </div>
          </form>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        className="LeadBox scrolldiv"
        show={showfollowup}
        onHide={handleCloseFolloup}
        placement={"end"}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Followup</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-body modalpadding">
              <div className="row">
                <>
                  <div className="form-group col-md-6">
                    <label>Followup Date</label>
                    <br />
                    <input
                      type="date"
                      name="Followup_Date_fiiling_team"
                      {...register('Followup_Date_fiiling_team', {
                        required: 'Please select the followup date'
                      })}
                      className="form-control"
                      readOnly
                    />
                    {errors.Followup_Date_fiiling_team &&
                      <div className="error-message">
                        {errors.Followup_Date_fiiling_team.message}
                      </div>}
                  </div>

                  <div className="form-group col-md-12">
                    <label>Notes</label>
                    <br />
                    <div className="form-floating">
                      <textarea
                        className="form-control"
                        placeholder="Leave a comment here"
                        id="floatingTextarea"
                        name="Notes_fiiling_team"
                        {...register('Notes_fiiling_team', { required: 'Please enter the notes' })}
                      ></textarea>
                      <label htmlFor="floatingTextarea">Notes</label>
                    </div>
                    {errors.Notes_fiiling_team &&
                      <div className="error-message">
                        {errors.Notes_fiiling_team.message}
                      </div>}
                  </div>

                  <div className="form-group col-md-6">
                    <label>Next Followup Date</label>
                    <br />
                    <input
                      type="date"
                      name="Next_Followup_Date_fiiling_team"
                      {...register('Next_Followup_Date_fiiling_team', { required: 'Please select next follow up date' })}
                      className="form-control"
                    />
                    {errors.Next_Followup_Date_fiiling_team &&
                      <div className="error-message">
                        {errors.Next_Followup_Date_fiiling_team.message}
                      </div>}
                  </div>
                </>
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-primary me-2 save">
                Save
              </button>
              <button
                type="reset"
                className="btn btn-secondary"
                onClick={handleCloseFolloup}
              >
                Cancel
              </button>
            </div>
          </form>


          {apiResponse && apiResponse.data && (
            <>
              <div className="col-md-12">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col" className="custom-th">Followup Status</th>
                      <th scope="col" className="custom-th">Followup Date</th>
                      <th scope="col" className="custom-th">Notes</th>
                      <th scope="col" className="custom-th">Next Followup Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {apiResponse.data && apiResponse.data.Filing_Team_Status.map((val, index) => (
                      <tr key={index}>
                        <td style={{ border: '1px solid #000' }}>{apiResponse.data.Filing_Team_Status[apiResponse.data.Filing_Team_Status.length - 1 - index]}</td>
                        <td style={{ border: '1px solid #000' }}>{formatLeadDate(apiResponse.data.Followup_Date_fiiling_team[apiResponse.data.Followup_Date_fiiling_team.length - 1 - index])}</td>
                        <td style={{ border: '1px solid #000' }}>{apiResponse.data.Notes_fiiling_team[apiResponse.data.Notes_fiiling_team.length - 1 - index]}</td>
                        <td style={{ border: '1px solid #000' }}>{formatLeadDate(apiResponse.data.Next_Followup_Date_fiiling_team[apiResponse.data.Next_Followup_Date_fiiling_team.length - 1 - index])}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>

      <>
        <Offcanvas show={show} onHide={handleClose} placement={"end"}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Services</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <form className="myformcheck">
              {serviceName.data && serviceName.data.length > 0 ? (
                serviceName.data.map((val, key) => (
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      value={val.service_name}
                      checked={selectedValue === val.service_name}
                      onChange={handleRadioChange}
                    />
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                      {val.service_name}
                    </label>
                  </div>
                ))
              ) : null}
              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mt-2"
                  onClick={handleButtonClick}
                >
                  Submit
                </button>
              </div>
            </form>
          </Offcanvas.Body>
        </Offcanvas>
      </>

      <div>
        <Offcanvas
          className="profileCanvas"
          show={followup}
          onHide={followupClose}
          placement={"end"}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              {apiResponse && apiResponse.data && (
                <div className="profilFlex">
                  <div className="prbg">
                    <i className="fa-solid fa-user" />
                  </div>
                  <h6 className="prftitle">{apiResponse.data.first_name}  {apiResponse.data.last_name} </h6>
                </div>
              )}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <form action="">
              {apiResponse && apiResponse.data && (
                <div className="container">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Name</h6>
                        <p>{apiResponse.data.first_name}</p>
                      </div>
                    </div>
                    {/* <div className="col-md-3">
                    <div className="namedetail">
                      <h6>Name</h6>
                      <p>Areon Foster</p>
                    </div>
                  </div> */}
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Gender</h6>
                        <p>{apiResponse.data.gender}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Email</h6>
                        <p>{apiResponse.data.email}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Age</h6>
                        <p>{apiResponse.data.age == "undefined" ? "" : apiResponse.data.age}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Contact No.</h6>
                        <p>{apiResponse.data.contact_number}</p>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Alt. Contact No. </h6>
                        <p>{apiResponse.data.alt_contact_number}</p>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Country</h6>
                        <p>{apiResponse.data.country_name}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>State</h6>
                        <p>{apiResponse.data.state_name}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Education</h6>
                        <p>{apiResponse.data.education}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Note</h6>
                        <p>{apiResponse.data.note}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Services</h6>
                        <p>{apiResponse.data.Services}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Upload File</h6>
                        {apiResponse.data.Upload_File == null ? "" : <img src={`https://api.checkcheckservices.in/public/${apiResponse.data.Upload_File}`} style={{ width: "150px" }} />}
                      </div>
                    </div>
                    <hr />
                  </div>
                  <div className="rowv mt-2">
                    <div className="col-md-12">
                      <div className="mytabpill">
                        <ul
                          className="nav nav-pills nav-justified my-2"
                          id="myTabs"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <a
                              className="nav-link active"
                              id="commentary-tab"
                              data-bs-toggle="pill"
                              href="#Commentary"
                              role="tab"
                            >
                              Details
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="videos-tab"
                              data-bs-toggle="pill"
                              href="#Videos"
                              role="tab"
                            >
                              Follow-ups
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="events-tab"
                              data-bs-toggle="pill"
                              href="#Events"
                              role="tab"
                            >
                              Payment
                            </a>
                          </li>

                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="filing-tab"
                              data-bs-toggle="pill"
                              href="#Filing"
                              role="tab"
                            >
                              Filing Process
                            </a>
                          </li>
                        </ul>
                        <div className="tab-content">
                          <div
                            className="tab-pane fade show active"
                            id="Commentary"
                            role="tabpanel"
                          >
                            <div className="row">
                              <div className="col-md-12 my-3">
                                <div className="accrordiondiv">
                                  <div
                                    className="accordion"
                                    id="accordionExample"
                                  >
                                    <div className="accordion-item">
                                      <h2
                                        className="accordion-header"
                                        id="headingOne"
                                      >
                                        <button
                                          className="accordion-button collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapseOne"
                                          aria-expanded="true"
                                          aria-controls="collapseOne"
                                        >
                                          Lead Information
                                        </button>
                                      </h2>
                                      <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                      >
                                        <div className="accordion-body">
                                          <table className="table">
                                            <tbody>
                                              <tr>
                                                <th>Lead Status</th>
                                                <td>{apiResponse.data.status_convert[apiResponse.data.status_convert.length - 1]}</td>
                                              </tr>

                                              <tr>
                                                <th>Lead Type</th>
                                                <td>{apiResponse.data.Lead_Type}</td>
                                              </tr>
                                              <tr>
                                                <th>Lead Source</th>
                                                <td>{apiResponse.data.Lead_Source}</td>
                                              </tr>
                                              <tr>
                                                <th>Lead Assign Sales</th>
                                                <td>{apiResponse.data.Lead_AssignName == null ? "N/A" : apiResponse.data.Lead_AssignName}</td>
                                              </tr>
                                              <tr>
                                                <th>Lead Assign Filling Manager</th>
                                                <td>{apiResponse.data.Filing_ManagerName == null ? "N/A" : apiResponse.data.Filing_ManagerName}</td>
                                              </tr>
                                              <tr>
                                                <th>Lead Assign Filling Team</th>
                                                <td>{apiResponse.data.Filing_TeamName == null ? "N/A" : apiResponse.data.Filing_TeamName}</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="Videos"
                            role="tabpanel"
                          >
                            {/* <div className="row">
                              <div className="col-md-12 mb-3">
                                <div className="table-responsive tablebg">
                                  <table className="table">
                                    <tbody>
                                      <tr>
                                        <th>Followup Status</th>
                                        <th>Follow up Date</th>
                                        <th>Note</th>
                                        <th>Next Follow up Date</th>
                                      </tr>
                                      {followUpStatus.Followup_Date_fiiling_team.slice().reverse().map((date, index) => (
                                        <tr key={index}>
                                          <td>{followUpStatus.Filing_Team_Status[followUpStatus.Followup_Date_fiiling_team.length - 1 - index]}</td>
                                          <td>{formatLeadDate(date)} {formatLeadTime(date)}</td>
                                          <td>{followUpStatus.Notes_fiiling_team[followUpStatus.Followup_Date_fiiling_team.length - 1 - index]}</td>
                                          <td>{followUpStatus.Next_Followup_Date_fiiling_team[followUpStatus.Followup_Date_fiiling_team.length - 1 - index] ? formatLeadDate(followUpStatus.Next_Followup_Date_fiiling_team[followUpStatus.Followup_Date_fiiling_team.length - 1 - index]) : ""}</td>
                                        </tr>
                                      ))}
                                      {followUpStatus.Followup_Date.slice().reverse().map((formattedDate, index) => (
                                        <tr key={index}>
                                          <td>{followUpStatus.status_convert[followUpStatus.Followup_Date.length - 1 - index]}</td>
                                          <td>{formatLeadDate(followUpStatus.Followup_Date[followUpStatus.Followup_Date.length - 1 - index])} {formatLeadTime(followUpStatus.Followup_Date[followUpStatus.Followup_Date.length - 1 - index])}</td>
                                          <td>{followUpStatus.Notes_sales[followUpStatus.Followup_Date.length - 1 - index]}</td>
                                          <td>{followUpStatus.Next_Followup_Date[followUpStatus.Followup_Date.length - 1 - index] ? formatLeadDate(followUpStatus.Next_Followup_Date[followUpStatus.Followup_Date.length - 1 - index]) : ""}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div> */}
                            <div className="row">
                              <div className="col-md-12 mb-3">
                                <div className="table-responsive tablebg">
                                  <table className="table">
                                    <tbody>
                                      <tr>
                                        <th>Followup Status</th>
                                        <th>Follow up Date</th>
                                        <th>Note</th>
                                        <th>Next Follow up Date</th>
                                      </tr>
                                      {
                                        sortedData.map((item, index) => (
                                          <tr key={index}>
                                            <td>{item.status}</td>
                                            <td>
                                              {item.date ? `${formatLeadDate(item.date)} ${formatLeadTime(item.date)}` : "N/A"}
                                            </td>
                                            <td>{item.notes}</td>
                                            <td>
                                              {item.nextFollowUpDate
                                                ? formatLeadDate(item.nextFollowUpDate)
                                                : "N/A"}
                                            </td>
                                          </tr>
                                        ))
                                      }
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="Events"
                            role="tabpanel"
                          >
                            <div className="row justify-content-center">
                              <div className="col-md-3">
                                <div className="namedetail">
                                  <h6>Total Payment</h6>
                                  {apiResponse.data.status_convert.includes('Convert') ? (
                                    <p>{followUpStatus.Total_Amount.slice().reverse().map((formattedDate, index) => (
                                      <tr key={index}>
                                        <td>${formattedDate}</td>
                                      </tr>
                                    ))}</p>
                                  ) : (
                                    <p>N/A</p>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-3">
                                <div className="namedetail">
                                  <h6>Payment Received</h6>
                                  {apiResponse.data.status_convert.includes('Convert') ? (
                                    <p>{followUpStatus.Amount_Paid.slice().reverse().map((formattedDate, index) => (
                                      <tr key={index}>
                                        <td>${formattedDate}</td>
                                      </tr>
                                    ))}</p>
                                  ) : (
                                    <p>N/A</p>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-3">
                                <div className="namedetail">
                                  <h6>Remaining Payment</h6>
                                  {apiResponse.data.status_convert.includes('Convert') ? (
                                    <p>{followUpStatus.Amount_Due.slice().reverse().map((formattedDate, index) => (
                                      <tr key={index}>
                                        <td>${formattedDate}</td>
                                      </tr>
                                    ))}</p>
                                  ) : (
                                    <p>N/A</p>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12 mb-3">
                                <div className="table-responsive tablebg">
                                  <table className="table">
                                    <tbody>
                                      <tr>
                                        <th>Date</th>
                                        <th>Amount</th>
                                        <th>Proof</th>
                                      </tr>

                                      {apiResponse.data.status_convert.includes('Convert') ? (
                                        followUpStatus.Payment_Proof_Date.slice().reverse().map((formattedDate, index) => (
                                          <tr key={index}>
                                            <td>{formatLeadDate(formattedDate)}</td>
                                            <td>{followUpStatus.Amount_Paid[followUpStatus.Payment_Proof_Date.length - 1 - index]}</td>
                                            <td>
                                              <a href={`https://api.checkcheckservices.in/public/${followUpStatus.Upload_Payment_Proof[followUpStatus.Payment_Proof_Date.length - 1 - index]}`} download={`PaymentProof-${index}`} target="_blank" >
                                                View
                                              </a>
                                            </td>
                                          </tr>
                                        ))
                                      ) : (
                                        <p></p>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="tab-pane fade"
                            id="Filing"
                            role="tabpanel"
                          >
                            <div className="container">
                              <div className="row">
                                <div className="col-md-12 my-3">
                                  <div className="accrordiondiv">
                                    <div
                                      className="accordion"
                                      id="accordionExample2"
                                    >
                                      <div className="accordion-item">
                                        <h2
                                          className="accordion-header"
                                          id="headingOne"
                                        >
                                          <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                          >
                                            Services
                                          </button>
                                        </h2>
                                        <div
                                          id="collapseOne"
                                          className="accordion-collapse collapse"
                                          aria-labelledby="headingOne"
                                          data-bs-parent="#accordionExample2"
                                        >
                                          <div className="accordion-body">
                                            {serviceName.data && serviceName.data.length > 0 ? (
                                              serviceName.data.map((val, key) => (
                                                <div className="form-check">
                                                  <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault1"
                                                    checked={apiResponse.data.Services === val.service_name}

                                                  />
                                                  <label
                                                    className="form-check-label forcheckfont"
                                                    htmlfor="flexRadioDefault1"
                                                  >
                                                    {val.service_name}
                                                  </label>
                                                </div>
                                              ))
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>

                                      <div className="accordion-item">
                                        <h2
                                          className="accordion-header"
                                          id="headingTwo"
                                        >
                                          <button
                                            class="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo"
                                          >
                                            Filing Status
                                          </button>
                                        </h2>
                                        <div
                                          id="collapseTwo"
                                          className="accordion-collapse collapse"
                                          aria-labelledby="headingTwo"
                                          data-bs-parent="#accordionExample2"
                                        >
                                          <div className="accordion-body accrfp">
                                            <div className="form-check frmch">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                defaultValue
                                                id="flexCheckDefault"
                                                name="caseInitiated"
                                                checked={caseInitiatedChecked}
                                                disabled
                                              />
                                              <div className="caseinitiate">
                                                <h6>Case Initiated</h6>
                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                              </div>
                                            </div>

                                            <div className="form-check frmch">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                defaultValue
                                                id="flexCheckDefault"
                                                name="fileIntiated"
                                                checked={fileInitiatedChecked}
                                                disabled
                                              />
                                              <div className="caseinitiate">
                                                <h6>
                                                  File Initiated or docs checklist
                                                  sent
                                                </h6>
                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                              </div>
                                            </div>

                                            <div className="form-check frmch">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                defaultValue
                                                id="flexCheckDefault"
                                                name="docsReceived"
                                                checked={docReceivedChecked}
                                                disabled
                                              />
                                              <div className="caseinitiate">
                                                <h6>
                                                  Docs received & Pending docs
                                                  sent{" "}
                                                </h6>
                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                              </div>
                                            </div>

                                            <div className="form-check frmch">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                defaultValue
                                                id="flexCheckDefault"
                                                name="sopprepration"
                                                checked={soppreprationChecked}
                                                disabled
                                              />
                                              <div className="caseinitiate">
                                                <h6>
                                                  Sop Or letters prepration &
                                                  Forms prep
                                                </h6>
                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                              </div>
                                            </div>

                                            <div className="form-check frmch">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                defaultValue
                                                id="flexCheckDefault"
                                                name="sopletters"
                                                checked={sopLetterChecked}
                                                disabled
                                              />
                                              <div className="caseinitiate">
                                                <h6>
                                                  SOP or Letters sent to client{" "}
                                                </h6>
                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                              </div>
                                            </div>

                                            <div className="form-check frmch">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                defaultValue
                                                id="flexCheckDefault"
                                                name="confirmrecieved"
                                                checked={confirmReceivedChecked}
                                                disabled
                                              />
                                              <div className="caseinitiate">
                                                <h6>
                                                  Confirmation received on SOP,
                                                  letters and docs, Forms
                                                  confirmation{" "}
                                                </h6>
                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                              </div>
                                            </div>

                                            <div className="form-check frmch">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                defaultValue
                                                id="flexCheckDefault"
                                                name="filesent"
                                                checked={fileSentChecked}
                                                disabled
                                              />
                                              <div className="caseinitiate">
                                                <h6>
                                                  File sent for Upload or
                                                  submission
                                                </h6>
                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                              </div>
                                            </div>

                                            <div className="form-check frmch">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                defaultValue
                                                id="flexCheckDefault"
                                                name="filessubmitted"
                                                checked={filessubmitted}
                                                disabled
                                              />
                                              <div className="caseinitiate">
                                                <h6>
                                                Files Submitted
                                                </h6>
                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                              </div>
                                            </div>
                                            <div className="form-check frmch">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                defaultValue
                                                id="flexCheckDefault"
                                                name="visaapproved"
                                                checked={visaApprovedChecked}
                                                disabled
                                              />
                                              <div className="caseinitiate">
                                                <h6>Visa approved</h6>
                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                              </div>
                                            </div>

                                            <div className="form-check frmch">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                defaultValue
                                                id="flexCheckDefault"
                                                name="visarefusal"
                                                checked={visaRefusalChecked}
                                                disabled
                                              />
                                              <div className="caseinitiate">
                                                <h6>Visa refusal</h6>
                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                              </div>
                                            </div>

                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </Offcanvas.Body>
        </Offcanvas>
      </div>

    </>
  );
}
