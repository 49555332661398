import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import host from "./utils";
import Sidebar from "../directives/barside";
import CustomNavbar from "../directives/navbar";
import profile2 from "../Assets/Images/topboxlogo.png";

const ZohoCrm = () => {
  // State to hold data, current page, items per page, and total rows
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default rows per page
  const [totalRows, setTotalRows] = useState(0); // Total rows to show in pagination

  // Fetch data from the backend API
  const fetchZohoData = async (page, limit) => {
    try {
      const response = await axios.get(`${host}/getzohocrmlist`, {
        params: {
          page: page,
          limit: limit,
        },
      });

      if (!response.data.error) {
        setData(response.data.data);
        setTotalRows(response.data.pagination.totalRecords); // Set the total number of records
      }
    } catch (error) {
      console.error("Error fetching Zoho CRM data:", error);
    }
  };

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = (newPerPage, page) => {
    setItemsPerPage(newPerPage);
    setCurrentPage(page); // Ensure the correct page is set
  };

  // Columns definition for the DataTable
  const columns = [
    { name: "Record Id", selector: (row) => row["Record Id"], sortable: true },
    { name: "Lead Name", selector: (row) => row["Lead Name"], sortable: true },
    { name: "Email", selector: (row) => row["Email"], sortable: true },
    { name: "Phone", selector: (row) => row["Phone"], sortable: true },
    {
        name: "Country",
        selector: (row) => row["Country  ( Located in)"],
        sortable: true,
    },
    { name: "Tag", selector: (row) => row["Tag"], sortable: true },
    { name: "Lead Status", selector: (row) => row["Lead Status"], sortable: true },
    { name: "Welcome Call Initiator", selector: (row) => row["Welcome Call Initiator"], sortable: true },
    { name: "Lead was Assigned to", selector: (row) => row["Lead was Assigned to"], sortable: true },
    { name: "First Name", selector: (row) => row["First Name"], sortable: true },
    { name: "Last Name", selector: (row) => row["Last Name"], sortable: true },
    { name: "Lead Number", selector: (row) => row["Lead Number"], sortable: true },
    { name: "Last Activity Time", selector: (row) => row["Last Activity Time"], sortable: true },
    { name: "Did the student receive Official IELTS Score?", selector: (row) => row["Did the student receive Official IELTS Score?"], sortable: true },
    { name: "Score in IELTS (Each and Over All )", selector: (row) => row["Score in IELTS (Each and Over All )"], sortable: true },
    { name: "Account Name.id", selector: (row) => row["Account Name.id"], sortable: true },
    { name: "Account Name", selector: (row) => row["Account Name"], sortable: true },
    { name: "Gender", selector: (row) => row["Gender"], sortable: true },
    { name: "City of Current Residence", selector: (row) => row["City of Current Residence"], sortable: true },
    { name: "Last Qualification", selector: (row) => row["Last Qualification"], sortable: true },
    { name: "College of Interest", selector: (row) => row["College of Interest"], sortable: true },
    { name: "Requested Field of Study", selector: (row) => row["Requested Field of Study"], sortable: true },
    { name: "Does Student have any Work Experience?", selector: (row) => row["Does Student have any Work Experience?"], sortable: true },
    { name: "Best time to Call", selector: (row) => row["Best time to Call"], sortable: true },
    { name: "Type of Application", selector: (row) => row["Type of Application"], sortable: true },
    { name: "Leads Scoring Rule Score", selector: (row) => row["Leads Scoring Rule Score"], sortable: true },
    { name: "Language of Instruction Interested", selector: (row) => row["Language of Instruction Interested"], sortable: true },
    { name: "Do you have B1 Level of French?", selector: (row) => row["Do you have B1 Level of French?"], sortable: true },
    { name: "Interested to study in French through Francization", selector: (row) => row["Interested to study in French through Francization"], sortable: true },
    // Add more columns based on your CSV data structure
  ];

  // Custom styling for the DataTable
  const customStyles = {
    rows: {
      style: {
        minHeight: "50px", // Set row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };

  // Effect to fetch data when current page or items per page changes
  useEffect(() => {
    fetchZohoData(currentPage, itemsPerPage);
  }, [currentPage, itemsPerPage]);

  return (
    <>
      <Sidebar />
      <CustomNavbar />
      <div className="main-content">
        <div className="container-fluid">
          <div className="topbox">
            <div className="row align-items-center">
              <div className="col-md-8">
                <div className="left-logo">
                  <div>
                    <img src={profile2} alt="Logo" className="logo" />
                  </div>
                  <p className="topboxttex">Lead Management</p>
                </div>
              </div>
              {/* <div className="right-logo"> */}
              <div className="col-md-4 ">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <a href="dashboard">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Leads
                    </li>
                  </ol>
                </nav>
                {/* </div> */}
              </div>
            </div>
          </div>
          <div className="row pt-3 my-3">
            <div className="col-lg-12">
              <div className="row">
                <div className="card">
                  <div className="card-header">
                    <DataTable
                      className="custom-data-table adminLeadManage-data-table"
                      columns={columns}
                      data={data}
                      customStyles={customStyles}
                      pagination
                      paginationTotalRows={totalRows}
                      onChangePage={handlePageChange}
                      paginationPerPage={itemsPerPage}
                      paginationRowsPerPageOptions={[10, 20, 30]} // Customize rows per page options
                      onChangeRowsPerPage={(newPerPage, page) =>
                        handleItemsPerPageChange(newPerPage, page)
                      }
                      paginationPerPageLabel="Rows per page"
                      selectableRows
                    />

                    {/* Pagination Controls */}
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <span>Rows per page:</span>
                      <select
                        value={itemsPerPage}
                        onChangeRowsPerPage={(newPerPage, page) =>
                          handleItemsPerPageChange(newPerPage, page)
                        }
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                      </select>
                      <span>
                        {`${(currentPage - 1) * itemsPerPage + 1}-${Math.min(
                          currentPage * itemsPerPage,
                          totalRows
                        )} of ${totalRows}`}
                      </span>
                      <div>
                        <button
                          onClick={() => handlePageChange(currentPage - 1)}
                          disabled={currentPage === 1}
                          className="mx-1"
                        >
                          Previous Page
                        </button>
                        <button
                          onClick={() => handlePageChange(currentPage + 1)}
                          disabled={currentPage * itemsPerPage >= totalRows}
                        >
                          Next Page
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ZohoCrm;
